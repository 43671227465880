import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'
import { useMobile } from 'src/hooks/useMobile'
import { useState, useEffect } from 'react'
import Breadcrumb from 'src/components/ui/Breadcrumb'

import './ItemsBrands.scss'

export type GetBrands = {
  allCmsMarcas: {
    nodes: Array<{
      id: string
      // eslint-disable-next-line
      sections: Array<{ data: any; id: string | null; name: string }>
    }>
  }
}

const Brands = () => {
  const { screenWidth } = useMobile()
  const [dktBrands, setDktBrands] = useState(true)
  // eslint-disable-next-line
  const [partnerBrands, setPartnerBrands] = useState<any>()

  useEffect(() => {
    if (screenWidth <= 1024) {
      setPartnerBrands(false)
    } else {
      setPartnerBrands(true)
    }
  }, [screenWidth])

  const data = useStaticQuery<GetBrands>(graphql`
    query GetBrandsData {
      allCmsMarcas {
        nodes {
          sections {
            data
            id
            name
          }
        }
      }
    }
  `)

  const brandDecathlon = data.allCmsMarcas.nodes.filter((brand) => {
    if (brand.sections[0].data.items.sectionA.brandType === 'Marca Decathlon') {
      return brand
    }

    return null
  })

  const brandPartner = data.allCmsMarcas.nodes.filter((brand) => {
    if (brand.sections[0].data.items.sectionA.brandType === 'Marca Parceira') {
      return brand
    }

    return null
  })

  const { pathname } = useLocation()
  const arrayPathname = pathname
    .split('/')
    .map((path: string, index: number) => {
      return {
        item: path,
        name: path.slice(1).slice(0, -1).split('/').pop()!,
        position: index + 1,
      }
    })

  return (
    <div className="content-brand">
      {/* BREADCRUMB */}
      <div className="mb-6">
        <Breadcrumb
          breadcrumbList={arrayPathname}
          // name="Marcas"
        />
      </div>

      <div className="content-seo-top px-5 md:p-0">
        <h1 className="text-dark3 text-2xl font-bold m-0">Marcas esportivas</h1>
        <p className="mt-4 text-darkGray text-sm">
          Seja qual for o seu esporte preferido, a Decathlon tem uma ampla gama
          de marcas exclusivas e líderes de mercado em diversas categorias.
        </p>
      </div>

      {/* TABS LAYOUT MOBILE */}
      {screenWidth <= 1023 && (
        <section className="tabs-mobile">
          <button
            onClick={() => {
              setDktBrands(true)
              setPartnerBrands(false)
            }}
            className={`change-decathlon change-button__${dktBrands}`}
          >
            Decathlon
          </button>
          <button
            onClick={() => {
              setDktBrands(false)
              setPartnerBrands(true)
            }}
            className={`change-partner change-button__${partnerBrands}`}
          >
            Parceiras
          </button>
        </section>
      )}

      {/* DECATHLON */}
      {dktBrands && (
        <section className="section-decathlon">
          <h2 className="hidden md:block">Decathlon</h2>
          <div className="section-brands">
            {/* eslint-disable-next-line */}
            {brandDecathlon.sort().map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  style={{
                    order: item.sections[0].data.items.sectionA.brandPriority,
                  }}
                >
                  <a href={item.sections[0].data.items.sectionA.brandURL}>
                    <div className="item-card-brand">
                      <section className="item-logo">
                        <img
                          alt={item.sections[0].data.items.sectionA.brandName}
                          title={
                            item.sections[0].data.items.sectionA.brandSEOTitle
                          }
                          src={item.sections[0].data.items.sectionA.brandLogo}
                        />
                      </section>
                      <section className="item-category">
                        <h3 className="brand-name font-bold text-sm text-neutral06">
                          {item.sections[0].data.items.sectionA.brandName}
                        </h3>
                        <span className="brand-category">
                          {item.sections[0].data.items.sectionA.brandSports}
                        </span>
                      </section>
                    </div>
                  </a>
                </div>
              )
            })}
          </div>
        </section>
      )}

      {/* PARTNER */}

      {partnerBrands && (
        <section className="section-partner">
          <h2 className="hidden md:block">Parceiras</h2>
          <div className="section-brands">
            {/* eslint-disable-next-line */}
            {brandPartner.sort().map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  style={{
                    order: item.sections[0].data.items.sectionA.brandPriority,
                  }}
                >
                  <a href={item.sections[0].data.items.sectionA.brandURL}>
                    <div className="item-card-brand">
                      <section className="item-logo">
                        <img
                          alt={item.sections[0].data.items.sectionA.brandName}
                          title={
                            item.sections[0].data.items.sectionA.brandSEOTitle
                          }
                          src={item.sections[0].data.items.sectionA.brandLogo}
                        />
                      </section>
                      <section className="item-category">
                        <h3 className="brand-name font-bold text-sm text-neutral06">
                          {item.sections[0].data.items.sectionA.brandName}
                        </h3>
                        <span className="brand-category">
                          {item.sections[0].data.items.sectionA.brandSports}
                        </span>
                      </section>
                    </div>
                  </a>
                </div>
              )
            })}
          </div>
        </section>
      )}
    </div>
  )
}

export default Brands
